import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { storageKeys } from 'src/app/services/core/storage/storage-keys';
import { StorageService } from 'src/app/services/core/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private storage: StorageService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const savedData = this.storage.getSavedData();
    if (savedData) {
      if ((route.routeConfig.path.includes("login") || route.routeConfig.path.includes("forgot-password")) && !savedData[storageKeys.authtoken]) {
        return true;
      } else if (savedData[storageKeys.authtoken] && route.routeConfig.path.includes("admin")) {
        return true;
      } else if (savedData[storageKeys.authtoken] && (route.routeConfig.path.includes("login") || route.routeConfig.path.includes("forgot-password"))) {
        this.router.navigate(['/admin/dashboard']);
        return false;
      } else if ((!savedData[storageKeys.authtoken]) && route.routeConfig.path.includes("admin")) {
        this.router.navigate(['login']);
        return false;
      }
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }
}
