import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { endpoint } from '../const/apiEndpoints';
import { HttpWrapperService } from '../core/http/http-wrapper/http-wrapper.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private toastNotification: ToastrService,
    private httpWrapper: HttpWrapperService,
    private spinner: NgxSpinnerService,
  ) { }

  //  Success toastee notification  **  //
  successToaster(msg, maxShown: any = '1') {
    this.toastNotification.success(msg);
  }

  //  Error toaster notification  **  //
  errorToast(msg) {
    this.toastNotification.error(msg)
  }

  // Page loading spinner **  //
  presentSpinner() {
    this.spinner.show();
  }

  // Page loading dismisspinner **  //

  dismissSpinner() {
    this.spinner.hide();
  }

  calculateEntries(list: Array<any>, current_page: number, limit: number, totalResult: number): Entries {
    if (list.length === totalResult) {
      let pages = {
        1: []
      };
      let arr = [];
      let page = 1;
      for (let i = 0; i < list.length; i++) {
        arr.push(list[i]);
        if (arr.length == limit) {
          pages[page] = arr;
          page += 1;
          arr = [];
        } else {
          if (i == list.length - 1) {
            pages[page] = arr;
            page += 1;
            arr = [];
          }
        }
      }
      list = pages[current_page];
    }
    return {
      from: limit * (current_page - 1) + 0 + 1,
      to: limit * (current_page - 1) + (list.length - 1) + 1,
      total: totalResult,
    };
  }

  getUnAssignedSchools(rrp_id: string) {
    return this.httpWrapper.getApi(endpoint.unAssignedSchool(rrp_id)).map(
      (res) => {
        return res;
      }
    ).toPromise()
      .catch(
        (error) => {

        }
      )
  }

  getPeople(role: "RM" | "RRP") {
    return this.httpWrapper.getApi(endpoint.people(role)).map(
      (res) => {
        return res;
      }
    ).toPromise()
      .catch(
        (error) => {

        }
      )
  }

}

export interface Entries {
  from: any;
  to: any;
  total: any;
}