<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Terms of Service</title>

</head>

<body>
    <div class="header">
        <div class="container"><a href="#" class="logo"><img src="assets/images/logo-dark.svg"></a></div>
    </div>
    <div class="content-page">
        <div class="container">
            <h1 class="heading-title text-center">Terms of Service</h1>
            <div class="content">
                <h3><strong>1. Terms</strong></h3>
                <p>By accessing the Good Schools App, you are agreeing to be bound by these terms of service, all
                    applicable laws and
                    regulations, and agree that you are responsible for compliance with any applicable local laws. If
                    you do not agree with
                    any of these terms, you are prohibited fr Good Schools App om using or accessing this Mobile App.
                    The materials
                    contained in this Mobile App are protected by applicable copyright and trademark law.</p>

                <h3><strong>Personal Information</strong></h3>
                <p>We only ask for personal information when we truly need it to provide a service to you. We collect it
                    by fair and lawful
                    means, with your knowledge and consent. We also let you know why we are collecting it and how it
                    will be used.
                </p>

                <h3><strong>2. Use License</strong></h3>
                <p>Permission is granted to temporarily download one copy of the materials (information or software) on
                    Good Schools App
                    for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer
                    of title, and under
                    this license you may not: modify or copy the materials; use the materials for any commercial
                    purpose, or for any public
                    display (commercial or non-commercial); attempt to decompile or reverse engineer any software
                    contained on Good Schools
                    App; remove any copyright or other proprietary notations from the materials; or transfer the
                    materials to another person
                    or "mirror" the materials on any other server.</p>

                <p>This license shall automatically terminate if you violate any of these restrictions and may be
                    terminated by Raising
                    Voices at any time. Upon terminating your viewing of these materials or upon the termination of this
                    license, you must
                    destroy any downloaded materials in your possession whether in electronic or printed format.</p>

                <h3><strong>3. Disclaimer</strong></h3>
                <p>The materials on Good Schools App are provided on an 'as is' basis. Raising Voices makes no
                    warranties, expressed or
                    implied, and hereby disclaims and negates all other warranties including, without limitation,
                    implied warranties or
                    conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual
                    property or other
                    violation of rights.</p>

                <p>Further, Raising Voices does not warrant or make any representations concerning the accuracy, likely
                    results, or
                    reliability of the use of the materials on its mobile app or otherwise relating to such materials or
                    on any sites linked
                    to this mobile app.</p>

                <h3><strong>4. Limitations</strong></h3>
                <p>In no event shall Raising Voices or its suppliers be liable for any damages (including, without
                    limitation, damages for
                    loss of data or profit, or due to business interruption) arising out of the use or inability to use
                    the materials on
                    Good Schools App, even if Raising Voices or a their authorized representative has been notified
                    orally or in writing of
                    the possibility of such damage. Because some jurisdictions do not allow limitations on implied
                    warranties, or
                    limitations of liability for consequential or incidental damages, these limitations may not apply to
                    you.</p>

                <h3><strong>5. Accuracy of materials</strong></h3>
                <p>The materials appearing on Good Schools App could include technical, typographical, or photographic
                    errors. Raising
                    Voices does not warrant that any of the materials on its mobile app are accurate, complete or
                    current. Raising Voices
                    may make changes to the materials contained on its mobile app at any time without notice. However
                    Raising Voices does
                    not make any commitment to update the materials.</p>

                <h3><strong>6. Links</strong></h3>
                <p>Raising Voices has not reviewed all of the sites linked to its mobile app and is not responsible for
                    the contents of any
                    such linked site. The inclusion of any link does not imply endorsement by Raising Voices of the
                    site. Use of any such
                    linked website is at the user's own risk.</p>

                <h3><strong>7. Modifications</strong></h3>
                <p>Raising Voices may revise these terms of service for its mobile app at any time without notice. By
                    using this Good
                    Schools App you are agreeing to be bound by the then current version of these terms of service.</p>

                <h3><strong>8. Governing Law</strong></h3>
                <p>These terms and conditions are governed by and construed in accordance with the laws of Uganda and
                    you irrevocably
                    submit to the exclusive jurisdiction of the courts in that location.</p>




            </div>
        </div>
    </div>
    <footer class="copyright">
        ©Good school, 2021. All rights reserved.
    </footer>
</body>

</html>