export const endpoint = {
    login: "/login",
    Administrator: (page: string, keyword: string) => { return `/list?page=` + page + `&limit=10` + (keyword ? `&like=${keyword}` : '') },
    addAdministrator: "/add",
    editAdministrator: (administrator_id: string) => { return `/edit/${administrator_id}` },
    deletedAdministrator: (administrator_id: string) => { return `/delete/${administrator_id}` },
    setAdministratorpassword: (administrator_id: string) => { return `/set-password/${administrator_id}` },
    changePassword: "/change-password",
    resetpassword: "/reset-password",
    sendMail: "/forgot-password",
    logOut: "/logout",
    rRPList: (page: string) => { return `/people/resource-persons?page=` + page + `&limit=10` },
    rRpStatus: (id: string) => { return `/people/change-status/${id}` },
    partnerStatus: (id: string) => { return `/people/change-status/${id}` },
    // partnerSearch: (pertNerName: string) => { return `/people/partner?like=` + pertNerName },
    //dev.api.raisingvoices.org/admin/partner?like=Mohan
    addRRPrMPartner: "/people/add",
    addRRPDetails: (id: string) => { return `/people/${id}` },
    rManagerList: (page: string, keyword: string) => { return `/people/regional-managers?page=` + page + `&limit=10` + (keyword ? `&like=${keyword}` : '') },
    deleteRRP: (id: string) => { return `/people/${id}` },
    deletePartner: (id: string) => { return `/people/${id}` },
    setUserpassword: (person_id: string) => { return `//people/set-password/${person_id}` },
    viewPersionDetails: (id: string) => { return `/people/${id}` },
    editRRP: (id: string) => { return `/people/${id}` },
    editPartner: (id: string) => { return `/people/${id}` },
    editRM: (id: string) => { return `/people/${id}` },
    deleterM: (id: string) => { return `/people/${id}` },
    partnerList: (page: string, keyword: string) => { return `/people/partners?page=` + page + `&limit=20` + (keyword ? `&like=${keyword}` : '') },
    // getlistToolkitPrimar: "/toolkit/primary",
    getlistToolkitPrimar: (page: string, keyword: string) => { return `/toolkit/primary?page=` + page + `&limit=10` + (keyword ? `&like=${keyword}` : '') },
    deletePrimaryToolkit: (id: string) => { return `/toolkit/${id}` },
    schoolName: (schoolName: string) => { return `/toolkit/primary?like=` + schoolName },
    editSchool: (school_id: string) => { return `/school/${school_id}` },
    addSchool: `/schools`,
    addToolKitPrimary: "/toolkit/add",
    downloadschoollist: "/school-download-list",
    statusToolKitPrimary: (id: string) => { return `/toolkit/change-status/${id}` },
    editToolkitPrimary: (id: string) => { return `/toolkit/${id}` },
    addtoolKitsecondry: "/toolkit/add",
    edittoolKitsecondry: (id: string) => { return `/toolkit/${id}` },
    deletetoolKitsecondry: (id: string) => { return `/toolkit/${id}` },
    getListsecondry: (page: string, keyword: string) => { return `/toolkit/secondary?page=` + page + `&limit=10` + (keyword ? `&like=${keyword}` : '') },
    statusToolKitsecondry: (id: string) => { return `/toolkit/change-status/${id}` },
    moduleList: (page: string) => { return `/modules?page=` + page + `&limit=10` },
    dashboardmoduleList: (page: string) => { return `/modules?page=` + page },
    moduleExport: (limit: string) => { return `/modules?page=1&limit=` + limit },
    deleteModule: (id: string) => { return `/module/${id}` },
    editModule: (id: string) => { return `/module/${id}` },
    addModule: "/module",
    rRPSearch: (searchName: string) => { return `/people/resource-persons?like=` + searchName },
    moduleSearch: (moduleName: string) => { return `/modules?like=` + moduleName },
    listOfComponents: (moduleid: string) => { return `/module/components/${moduleid}` },
    componentChangeOrder: (moduleid: string) => { return `/module/component/change-order/${moduleid}` },
    randomizingQuestionsStatus: (module_id: string) => { return `/module/component/question/randomizing-order/${module_id}` },
    componentStatus: (component_id: string) => { return `/module/component/change-status/${component_id}` },
    componentDelete: (module_id: string, comp_id: string) => { return '/module/component/' + module_id + '/' + comp_id },
    componentAdd: (moduleid: string) => { return `/module/component/${moduleid}` },
    componentDetails: (module_id: string, comp_id: string) => { return '/module/component/' + module_id + '/' + comp_id },
    componentEdit: (id: string) => { return `/module/component/${id}` },
    getSchoolList: (page: string, keyword: string) => { return `/schools?page=` + page + `&limit=20` + (keyword ? `&like=${keyword}` : '') },
    searchSchool: (schoolName: string) => { return `/schools?like=` + schoolName },
    unAssignedSchool: (unAssignedSchool_id: string) => { return `/school-for-assign${unAssignedSchool_id ? '?peopleId=' + unAssignedSchool_id : ''}` },
    people: (role: 'RM' | 'RRP') => { return `/peoples?role=${role}` },
    unassignedRRP: (rm_id: string) => { return `/people/rrp-for-assign${rm_id ? '?peopleId=' + rm_id : ''}` },
    schoolDetail: (school_id: string) => { return `/school/${school_id}` },
    activityRRpList: `/activities`,
    activitiesRRplist: "/people/resource-persons",
    activitiesSchoollist: "/schools",
    addSurvey: "/survey",
    surveyList: (page?: number, limit?: number) => { return `/surveys${page && limit ? `?page=${page}&limit=${limit}` : ''}` },
    deleteSurvey: (surveyId: string) => { return `/survey/${surveyId}` },
    surveyStatus: (survey_id: string) => { return `/survey/change-status/${survey_id}` },
    addSurveyQuestion: "/survey/question",
    editSurveyQuestion: (question_id: string) => { return `/survey/question/${question_id}` },
    surveyQuestionList: (survey_id: string) => { return `/survey/question/${survey_id}` },
    changeSurveyOrder: (question_id: string) => { return `/survey/questions/change-order/${question_id}` },
    deleteSurveyQuestion: (question_id: string) => { return `/survey/question/${question_id}` },
    surveyQuestionStatus: (question_id: string) => { return `/survey/question/change-status/${question_id}` },
    surveyQuestionsOptions: "/survey/option",
    downloadTools: (school_id: string) => { return `/school/download/${school_id}` },
    deleteOption: (question_id: string, option_id: string) => { return `/survey/option/${question_id}/${option_id}` },
    componentassessmentList: (module_id: string, component_id: string) => { return `/assessment/module/questions/${module_id}/${component_id}` },
    deleteComponentAssessmentQuestion: (question_id: string) => { return `/assessment/question/${question_id}` },
    ComponentAssesmentQuestionStatus: (question_id: string) => { return `/assessment/question/change-status/${question_id}` },
    uploadFile: "/assessment/option/media-upload",
    moduleComponentQuestion: "/assessment/question",
    surveyQuestionOtionMediaUpload: "/survey/option/media-upload",
    addmoduleComponentOptions: "/assessment/option",
    editModuleQuestion: (question_id: string) => { return `/assessment/question/${question_id}` },
    moduleQuestionStatus: (question_id: string) => { return `/assessment/question/change-status/${question_id}` },
    chageModuleOrder: "/assessment/change-order",
    globalAssessmentQuestions: (id: string) => { return `/assessment/global/questions/${id}` },
    downloadSurvey: (survey_id: string) => { return `/survey/download/${survey_id}` },
    changeOrderGlobalAssessment: "/assessment/change-order",

    getGlobalAssessment: (page?: number, limit?: number) => { return `/global-assessments${page && limit ? `?page=${page}&limit=${limit}` : ''}` },
    addGlobalAssessment: "/global-assessments",
    editGlobalAssessment: (id: string) => { return `/global-assessments/${id}` },
    deleteGlobalAssessment: (id: string) => { return `/global-assessments/${id}` },
    changeGlobalAssessmentStatus: (id: string) => { return `/global-assessments/change-status/${id}` },

    moduleAssessmentAttendenByRRP: (rrp_id: string) => { return `/people/module-assessment/${rrp_id}` },
    globalAssessmentAttendenByRRP: (rrp_id: string) => { return `/people/global-assessment/${rrp_id}` },

    // START: Report
    moduleAssessmentListForReport: "/module/component/assessments",
    getCheckInTools: "/activities/toollist",
    downloadModuleAssessMentReport: (query: { id: string; startDate: string; endDate: string }) => { return `/report/download/module-assesment?componentId=${query.id}&startDate=${query.startDate}&endDate=${query.endDate}` },
    downloadGlobalAssessMentReport: (query: { id: string; startDate: string; endDate: string }) => { return `/report/download/global-assesment?globalId=${query.id}&startDate=${query.startDate}&endDate=${query.endDate}` },
    downloadsurveyReport: (query: { id: string; startDate: string; endDate: string }) => { return `/report/download/survey?surveyId=${query.id}&startDate=${query.startDate}&endDate=${query.endDate}` },
    downloadCheckInTool: (query: { id: string; startDate: string; endDate: string }) => { return `/report/download/activity-tool?toolType=${query.id}&startDate=${query.startDate}&endDate=${query.endDate}` },
    // END: Report

    // RRP Activity download
    "rrpActivityDownload": "/report/download/rrp-activity",


    //Dashboard data

    learningscore: "/dashboard/learning-score",
    rrptool: "/dashboard/rrp-vacpc-tool",
    checkinschool: "/dashboard/checkins-school",
    communitytool: "/dashboard/community-tool",
    activitytool: "/dashboard/activity-tool",
    gstscoretool: "/dashboard/gst-score-tool",
    gstschool: "/dashboard/gst-school",
    districtlist: "/school/district/list",

    //Reset Points

    ResetPoints: "/point-reset",
    GetResetPoints: "/point-reset",
}
