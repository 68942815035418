import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common/common.service';
import { storageKeys } from '../../storage/storage-keys';
import { StorageService } from '../../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {


  constructor(
    private storage: StorageService,
    private router: Router,
    private commonService: CommonService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const savedData = this.storage.getSavedData();
    let header = {};
    if (savedData && savedData[storageKeys.AdminData]) {
      header = {
        "Authorization": savedData[storageKeys.AdminData].authToken
      };
    }

    const updatedRequest = request.clone({
      headers: new HttpHeaders(header)
    });
    return next.handle(updatedRequest).pipe(
      tap(
        (event: HttpEvent<any>) => {
        }, (error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status == 401) {
              this.storage.clearStorageForLogout();
              this.commonService.errorToast('Session expired!');
              this.router.navigate(['/login']);
            }else{
              // this.commonService.errorToast(error.error.message);
            }
          }
        }
      )
    );
  }
}
