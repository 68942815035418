<div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
    <div class="sc-ion-alert-ios-backdrop"></div>
    <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
      <div class="alert-head sc-ion-alert-ios">
        <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete User</h2>
      </div>
      <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?</div>
      <div class="alert-button-group sc-ion-alert-ios">
        <button type="button" class="alert-button" data-dismiss="modal" aria-label="Close"><span class="alert-button-inner sc-ion-alert-ios">Yes</span>
        </button>
        <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
      </div>
    </div> 
  </div>