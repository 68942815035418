import { NgModule } from "@angular/core";
import { UrlPipe } from "./url/url.pipe";

@NgModule({
    declarations: [
        UrlPipe
    ],
    exports: [
        UrlPipe
    ]
})

export class PipesModule { }