import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'url'
})
export class UrlPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }
    if (value.startsWith('data:')) {
      return value;
    }
    return value ? value.includes('https://') ? value : 'https://' + value : '';
  }

}
