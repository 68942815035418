import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import "rxjs/add/operator/map";
import 'rxjs/add/operator/toPromise';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpWrapperService {

  constructor(
    private HttpClient: HttpClient
  ) {
  }

  getRequestUrl(endpoint: string) {
    return environment.baseUrl + endpoint;
  }

  private generateHttpHeaders(options: HttpInputData) {
    let httpInput: any = {};
    if (options) {
      if (options.headers) {
        httpInput.headers = options.headers;
      }
      if (options.params) {
        httpInput.params = options.params;
      }
      if (options.observe) {
        httpInput.observe = options.observe;
      }
      if (options.responseType) {
        httpInput.responseType = options.responseType;
      }
    } else {
      httpInput.observe = 'response'
    }
    return httpInput;
  }

  //  Post Api function **//
  postApi(endpoint: string, data: any, options?: HttpInputData): Observable<any> {
    return this.HttpClient.post(this.getRequestUrl(endpoint), data, this.generateHttpHeaders(options));
  }

  //  Get Api function **//
  getApi(endpoint: string, options?: HttpInputData): Observable<any> {
    return this.HttpClient.get(this.getRequestUrl(endpoint), this.generateHttpHeaders(options));

  }

  //  Put Api function **//
  putApi(endpoint: string, obj: any, options?: HttpInputData): Observable<any> {
    return this.HttpClient.put(this.getRequestUrl(endpoint), obj, this.generateHttpHeaders(options));
  }

  deleteApi(endpoint: string, options?: HttpInputData): Observable<any> {
    return this.HttpClient.delete(this.getRequestUrl(endpoint), this.generateHttpHeaders(options));
  }

}

export class HttpInputData {
  headers: HttpHeaders = null;
  params: HttpParams = null;
  authentication: boolean = false;
  observe: 'body' | 'events' | 'response' = 'response';
  responseType: XMLHttpRequestResponseType;
}
