import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})
export class ExportService {


  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }



  public exportAsExcelFileForSurvey(surveyQuestion: any[], excelFileName: string): void {
    const surveyRelatedQuestionWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(surveyQuestion, { cellStyles: true });
    const workbook: XLSX.WorkBook = { Sheets: { 'Survey History': surveyRelatedQuestionWorksheet }, SheetNames: ['Survey History'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFileSurvey(excelBuffer, excelFileName);
  }

  private saveAsExcelFileSurvey(buffer: any, fileName: string): void {
    const Survey: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(Survey, fileName + EXCEL_EXTENSION);
  }

  public exportAsExcelFileForTools(surveyQuestion: any[], excelFileName: string): void {
    const toolsRRpWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(surveyQuestion, { cellStyles: true });
    const workbook: XLSX.WorkBook = { Sheets: { 'RRP Activity or Tools': toolsRRpWorksheet }, SheetNames: ['RRP Activity or Tools'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFileTools(excelBuffer, excelFileName);
  }

  private saveAsExcelFileTools(buffer: any, fileName: string): void {
    const rrp: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(rrp, fileName + EXCEL_EXTENSION);
  }


  public exportAsExcelFileForSchool(surveyQuestion: any[], excelFileName: string): void {
    const schoolToolsWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(surveyQuestion, { cellStyles: true });
    const workbook: XLSX.WorkBook = { Sheets: { 'School Details or Tools': schoolToolsWorksheet }, SheetNames: ['School Details or Tools'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFileSchool(excelBuffer, excelFileName);
  }

  private saveAsExcelFileSchool(buffer: any, fileName: string): void {
    const schoolTools: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(schoolTools, fileName + EXCEL_EXTENSION);
  }


}
