import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { TermsofserviceComponent } from './components/termsofservice/termsofservice.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RegisterMailComponent } from './register-mail/register-mail.component';
import { storageKeys } from './services/core/storage/storage-keys';

class RedirectUrl {
  static getRedirectUrl() {
    const savedData = JSON.parse(JSON.parse(localStorage.getItem(storageKeys.authtoken)));
    if (savedData) {
      return "/admin/dashboard";
    } else {
      return "/login";
    }
  }
}

const routes: Routes = [
  { path: '', redirectTo: RedirectUrl.getRedirectUrl(), pathMatch: 'full' },
  { path: 'admin', loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'reset-password', component: ForgotPasswordComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password', component: RegisterMailComponent, canActivate: [AuthGuard] },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'privacy-policy', component: PrivacypolicyComponent },
  { path: 'terms-condition', component: TermsofserviceComponent },

  { path: '**', redirectTo: RedirectUrl.getRedirectUrl() }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
