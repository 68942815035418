import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../common/common.service';
import { endpoint } from '../const/apiEndpoints';
import { HttpWrapperService } from '../core/http/http-wrapper/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    public router: Router,
    private httpWrapper: HttpWrapperService,
    private commonService:CommonService
  ) {

  }


  async login(payload: { "email": string, "password": string }) {
    return this.httpWrapper.postApi(endpoint.login, payload).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;
        }
      );
  }

  //*********************Register mail..

 async registerMail(payload:{"email":string}) {
    return this.httpWrapper.postApi(endpoint.sendMail,payload).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
        this.commonService.errorToast(error.error.message);
        }
      );
  }

  //***************************Reset password...

  async resetPassword(payload:{"newPassword" : string, "confirmPassword" : string, "token" : string}){
    return this.httpWrapper.postApi(endpoint.resetpassword,payload).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
        this.commonService.errorToast(error.error.message);
        }
      );
  }
 }
