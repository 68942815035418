import { Injectable } from '@angular/core';
import { storageKeys } from './storage-keys';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private savedData: any = {};

  constructor() { }

  public getSavedData() {
    this.savedData = {};
    Object.keys(storageKeys).forEach(
      (key) => {
        if (key) {
          let data = JSON.parse(JSON.parse(this.get(storageKeys[key])));
          if (data) {
            this.savedData[storageKeys[key]] = data;
          }
        }
      }
    );

    return this.savedData;
  }

  public set(key: string, data: any) {
    if (!data) {
      return;
    }
    return localStorage.setItem(key, JSON.stringify(JSON.stringify(data)));
  }

  private get(key: string) {
    return localStorage.getItem(key);
  }

  public removeItem(key: string) {
    return localStorage.removeItem(key);
  }

  private clearStorage() {
    localStorage.clear();
  }

  public clearStorageForLogout() {
    return new Promise((resolve, reject) => {
      let count = 0
      Object.keys(storageKeys).forEach(
        (key) => {
          // if (key && storageKeys[key] !== storageKeys.lastSelectedLanguage && storageKeys[key] !== storageKeys.rememberMe) {
          this.removeItem(storageKeys[key]);
          count++;
          // }
        }
      );
      if (count === Object.keys(storageKeys).length) {
        resolve(true);
      }
    })
  }
}
