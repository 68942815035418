<div class="auth-body-bg">
  <div class="home-btn d-none d-sm-block">
    <a><i class="mdi mdi-home-variant h2 text-white"></i></a>
  </div>
  <div>
    <div class="container-fluid p-0">
      <div class="row no-gutters">
        <div class="col-lg-4">
          <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
            <div class="w-100">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div>
                    <div class="text-center">
                      <div>
                        <a class="logo"><img src="assets/images/logo-dark.svg" height="100" alt="logo"></a>
                      </div>

                      <h4 class="font-size-18 mt-3">Register email</h4>
                    </div>

                    <div class="p-2 mt-3">
                      <form class="form-horizontal" action="index.html" [formGroup]="emailForm" (ngSubmit)="onSubmit()">
                        <!-- (ngSubmit)="onSubmit()" -->
                        <div class=" mb-4">
                          <div class="form-group auth-form-group-custom mb-0">
                            <i class="ri-user-2-line auti-custom-input-icon"></i>
                            <label for="username">Email</label>
                            <input type="text" class="form-control" formControlName="email"
                              placeholder="Enter register email"
                              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                              (keydown.space)="$event.preventDefault()">

                          </div>
                          <div class="error-msg" *ngIf="submitted && f.email.errors">
                            <span style="color:red" *ngIf="f.email.errors.required">Email is
                              required.</span>
                          </div>
                        </div>
                        <div class="mt-4 text-center">
                          <button class="btn btn-primary w-md waves-effect waves-light" type="submit">Send</button>
                        </div>
                      </form>
                    </div>

                    <div class="mt-3 text-center">
                      <p>Don't have an account ? <a [routerLink]="['/login']" class="font-weight-medium text-primary">
                          Log in </a> </p>
                      <p class=" copyright">© 2021 Good School Crafted with <i class="mdi mdi-heart text-danger"></i> by
                        <a href="https://greychaindesign.com/">Grey Chain</a>
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="authentication-bg">
            <div class="bg-overlay"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>