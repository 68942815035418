import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { CommonService } from 'src/app/services/common/common.service';
import { storageKeys } from 'src/app/services/core/storage/storage-keys';
import { StorageService } from 'src/app/services/core/storage/storage.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	password: boolean;
	submitted = false;
	adminData: any = [];

	constructor(private formBuilder: FormBuilder,
		private authService: AuthenticationService,
		private storage: StorageService,
		private router: Router,
		private commonService: CommonService) { }

	ngOnInit(): void {
		this.loginForm = this.formBuilder.group({
			"email": ['', Validators.required],
			"password": ['', Validators.required]
		})
	}
	showPassword() {
		this.password = !this.password;
	}

	//********login form controls return...
	get f() {
		return this.loginForm.controls;
	}


	//*******OnSubmit function for api...
	onSubmit() {
		this.submitted = true;
		if (this.loginForm.invalid) {
			this.commonService.dismissSpinner();
			return;
		}

		//******Payload object requet for api...
		let payload = {
			"email": this.loginForm.value.email.toLowerCase(),
			"password": this.loginForm.value.password,
		}
		this.commonService.presentSpinner();
		this.authService.login(payload).then(
			(res) => {
				this.commonService.dismissSpinner();
				console.log('errrors', res)
				if (res.status == 200) {
					this.adminData = res.body.AdminData;
					this.storage.set(storageKeys.AdminData, res.body.AdminData);
					this.storage.set(storageKeys.authtoken, res.body.AdminData.authToken);
					this.router.navigate(['admin/dashboard'])
				} else {
					this.commonService.dismissSpinner();
					this.commonService.errorToast(res.error.message);
				}
			}
		)
	}
}
