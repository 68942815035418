import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  password: boolean;
  confirmpassword: boolean;
  submitted = false;
  token: any;

  constructor(private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private commonService: CommonService,
    private router: Router) {
    this.token = window.location.href.split('?token=');

  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      "newPassword": ['', Validators.required],
      "confirmpassword": ['', Validators.required],
    });
  }
  showPassword() {
    this.password = !this.password;
  }
  showConfirmPassword() {
    this.confirmpassword = !this.confirmpassword;
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    let payload =
    {
      "newPassword": this.forgotPasswordForm.value.newPassword,
      "confirmPassword": this.forgotPasswordForm.value.confirmpassword,
      "token": this.token[1]
    }

    this.commonService.presentSpinner();
    this.auth.resetPassword(payload).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          this.router.navigate(['/login'])
          this.commonService.successToaster(res.body.message);
          this.submitted = false;
          this.forgotPasswordForm.reset();

        }
      }
    );


  }


}
