import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/auth/authentication.service';
import { CommonService } from '../services/common/common.service';
import { HomeService } from '../services/home/home.service';

@Component({
  selector: 'app-register-mail',
  templateUrl: './register-mail.component.html',
  styleUrls: ['./register-mail.component.scss']
})
export class RegisterMailComponent implements OnInit {
  emailForm: FormGroup;
  submitted = false;
  navUrl: any;
  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private commonService: CommonService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      "email": ['', Validators.required]
    })
  }

  get f() {
    return this.emailForm.controls;
  }

  //***********************Send link on email */
  onSubmit() {
    this.submitted = true;
    if (this.emailForm.invalid) {
      return;
    }
    let payload = {
      "url": "https://dev.admin.raisingvoices.org/reset-password",
      "email": this.emailForm.value.email,
    }
    this.commonService.presentSpinner();
    this.auth.registerMail(payload).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          this.commonService.successToaster(res.body.message);
          this.navUrl = res.body
          // this.router.navigateByUrl([res.reset-url])

        }

      }
    );


  }
}
