<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Privacy Policy</title>

</head>

<body>
    <div class="header">
        <div class="container"><a href="#" class="logo"><img src="assets/images/logo-dark.svg"></a></div>
    </div>
    <div class="content-page">
        <div class="container">
            <h1 class="heading-title text-center">Privacy Policy</h1>
            <div class="content">
                <h3><strong>Your privacy is important to us</strong></h3>
                <p>It is Raising Voices policy to respect your privacy regarding any information we may collect from you
                    across the Good
                    Schools mobile application.</p>

                <h3><strong>Personal Information</strong></h3>
                <p>We only ask for personal information when we truly need it to provide a service to you. We collect it
                    by fair and lawful
                    means, with your knowledge and consent. We also let you know why we are collecting it and how it
                    will be used.
                </p>

                <h3><strong>Collection</strong></h3>
                <p>We only retain collected information for as long as necessary to provide you with your requested
                    service. What data we
                    store, we will protect within acceptable means to prevent loss and theft, as well as unauthorised
                    access, disclosure,
                    copying, use or modification.</p>

                <h3><strong>Additional Terms</strong></h3>
                <p>We do not share any personally identifying information publicly or with third parties, except when
                    required by law.</p>

                <p>Our mobile app may link to external sites that we do not operate. Please be aware that we have no
                    control over the
                    content and practices of these sites and cannot accept responsibility or liability for their
                    respective privacy
                    policies.</p>

                <p>You are free to refuse our request for your personal information, with the understanding that we may
                    be unable to
                    provide you with some of your desired services.</p>

                <p>Your continued use of our mobile app will be regarded as an acceptance of our privacy and personal
                    information
                    practices. If you have any questions about how we handle user data and personal information, feel
                    free to contact us.</p>

            </div>
        </div>
    </div>
    <footer class="copyright">
        ©Good school, 2021. All rights reserved.
    </footer>
</body>

</html>