import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { AdminModule } from './components/admin/admin.module';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AlertPopupComponent } from './shared-components/alert-popup/alert-popup.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpInterceptorService } from './services/core/http/http-interceptor/http-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxPaginationModule } from 'ngx-pagination';
// import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ExportService } from './services/export/export.service';
import { CKEditorModule } from 'ngx-ckeditor';
import { PipesModule } from './pipes/pipes.module';
import { RegisterMailComponent } from './register-mail/register-mail.component';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { NgxImageCompressService } from 'ngx-image-compress';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { TermsofserviceComponent } from './components/termsofservice/termsofservice.component';

// import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		ForgotPasswordComponent,
		AlertPopupComponent,
		// PageNotFoundComponent,
		RegisterMailComponent,
		PrivacypolicyComponent,
		TermsofserviceComponent,
		// PageNotFoundComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		// AdminModule,
		NgApexchartsModule,
		NgxSpinnerModule,
		NgSelectModule,
		HttpClientModule,
		// AngularEditorModule,
		BrowserAnimationsModule,
		PdfViewerModule,
		NgxPaginationModule,
		CKEditorModule,
		ToastrModule.forRoot({
			maxOpened: 1,
			preventDuplicates: true,
		}),
		PipesModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpInterceptorService,
			multi: true
		},
		ExportService,
		DatePipe,
		NgxImageCompressService, TitleCasePipe
	], bootstrap: [AppComponent]
})
export class AppModule { }
